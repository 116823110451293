// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx": () => import("./../src/@lekoarts/gatsby-theme-cara/templates/cara.tsx" /* webpackChunkName: "component---src-lekoarts-gatsby-theme-cara-templates-cara-tsx" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-page-layout-article-page-layout-js": () => import("./../src/templates/ArticlePageLayout/ArticlePageLayout.js" /* webpackChunkName: "component---src-templates-article-page-layout-article-page-layout-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-articles-index-js": () => import("./../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */)
}

